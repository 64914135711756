import Axios from 'axios'
import { Stats } from '../Cohort/api'

const { REACT_APP_API_ACTIVATION } = process.env

const token = localStorage.getItem('token')

export const ApiActivation = Axios.create({
    baseURL: `${REACT_APP_API_ACTIVATION}`,
    headers: { Authorization: `Bearer ${token}` },
})

export const getNetworks = () =>
    ApiActivation.get<GetNetworksList>(`/admin/networks`).then(({ data }) => data.data.networks)

export const getNetworkById = (networkId: string) =>
    ApiActivation.get<GetNetwork>(`/admin/networks/${networkId}`).then(({ data }) => data.data)

export const addEditNetwork = (data: EditNetworkDTO | NewNetworkDTO) =>
    ApiActivation({
        method: 'id' in data ? 'PATCH' : 'POST',
        url: `/admin/networks`,
        data,
    }).then(({ data }) => data.data as NetworkDTO)

export const addEditFrequency = (data: FrequencyDTO | NewFrequencyDTO) =>
    ApiActivation({
        method: 'id' in data ? 'PATCH' : 'POST',
        url: `/admin/frequencies`,
        data,
    }).then(({ data }) => data.data as FrequencyDTO)

export const getSchedule = (id: string) =>
    ApiActivation.get<GetSchedule>(`/admin/schedules/${id}`).then(({ data }) => data.data)

export const addEditSchedule = (data: EditScheduleDTO | NewScheduleDTO) =>
    ApiActivation({
        method: 'id' in data ? 'PATCH' : 'POST',
        url: `/admin/schedules`,
        data,
    }).then(({ data }) => data.data as EditScheduleDTO)

export const editCohort = (data: {
    id: string
    data: Partial<Pick<CohortDTO, 'active' | 'limit'>>
}) =>
    ApiActivation.put<EditCohortDTO>(`/admin/cohorts/${data.id}`, data.data).then(
        ({ data }) => data.data,
    )

export const addCohort = (data: NewCohort) =>
    ApiActivation.post<CreateCohort>(`/admin/cohorts`, data).then(({ data }) => data.data)

export const networkStats = ({ id, from, to }: NetworkStatsProps) =>
    ApiActivation.get<Stats>(`/admin/profiles/stats?networkId=${id}&from=${from}&to=${to}`).then(
        ({ data }) => data.data,
    )

// Interfaces
type GetNetworksList = API<{ networks: NetworkDTO[] }>
type GetSchedule = API<ScheduleWithQuotasDTO>
type GetNetwork = API<NetworkDTO>
type EditCohortDTO = API<CohortDTO>
type CreateCohort = API<CohortDTO>

type DateArg = ConstructorParameters<typeof Date>[0]

type NetworkStatsProps = {
    id: string
    from: DateArg
    to: DateArg
}

export type NewNetworkDTO = Pick<NetworkDTO, 'contentType' | 'title'>
export type EditNetworkDTO = Pick<NetworkDTO, 'id' | 'contentType' | 'title'>
export type NetworkDTO = {
    id: string
    title: string
    contentType: string
    createdAt: Date
    updatedAt: Date
    frequencies: FrequencyDTO[]
    _count?: {
        courses: number
        frequencies: number
    }
}

export type NewFrequencyDTO = Omit<FrequencyDTO, 'id' | 'courses'>
export interface EditFrequencyDTO {
    id: string
    networkId: string
    title: string
    description: string
    sunday: boolean
    monday: boolean
    tuesday: boolean
    wednesday: boolean
    thursday: boolean
    friday: boolean
    saturday: boolean
}
export interface FrequencyDTO extends EditFrequencyDTO {
    courses: {
        networkId: string
        frequencyId: string
        id: string
        mongoId: string
        institution: string
        from: number
        to: number
        createdAt: string
        updatedAt: string
    }[]
}

export interface EditScheduleDTO extends NewScheduleDTO {
    id: string
}
export interface NewScheduleDTO {
    networkId: string
    frequencyId: string
    mongoId: string
    from: number
    to: number
    institution: string
}
export interface ScheduleWithQuotasDTO extends NewScheduleDTO {
    id: string
    createdAt: Date
    updatedAt: Date
    quotas: {
        courseId: string
        id: string
        taken: number
        limit: number
        active: boolean
        date: Date
        createdAt: Date
        updatedAt: Date
        _count: {
            profiles: number
        }
    }[]
    active: boolean
    alias: string
    name: string
}

export interface CohortDTO {
    courseId: string
    id: string
    taken: number
    limit: number
    active: boolean
    date: string
    createdAt: Date
    updatedAt: Date
}
export type NewCohort = Pick<CohortDTO, 'date' | 'active' | 'taken' | 'limit' | 'courseId'>

type API<T> = { data: T; message: string }

import { Column } from 'react-table'
import { Link } from 'react-router-dom'
import { Table } from '../../../Components/Table'
import { useNetworks } from '../Hooks/useNetworks'
import { NetworkDTO } from '../api'
import { useNetwork } from '../Hooks/useNetwork'

export const NetworksList = () => {
    const { networks, isError, isLoading } = useNetworks()

    if (isLoading) return <div className="info">Cargando redes...</div>
    if (isError) return <div className="danger">Se produjo un error inesperado</div>

    return <Table data={networks ?? []} columns={columns} canDownloadCsv={false} />
}

const columns: Column<NetworkDTO>[] = [
    {
        Header: 'Título',
        accessor: 'title',
    },
    {
        Header: 'Frecuencias',
        accessor: ({ _count }) => `${_count?.frequencies}`,
    },
    {
        Header: 'Horarios',
        accessor: ({ _count }) => `${_count?.courses}`,
    },
    {
        Header: 'Contenido',
        accessor: 'contentType',
    },
    {
        Header: 'Fecha creación',
        accessor: ({ createdAt }) => (createdAt ? new Date(createdAt).toLocaleDateString() : '-'),
    },

    {
        Header: '',
        id: 'options',
        accessor: ({ id }) => (
            <div className="flex">
                <SmartLink networkId={id} />
            </div>
        ),
    },
]

const SmartLink = ({ networkId }: { networkId: string }) => {
    const { prefetch: prefetchNetwork } = useNetwork(networkId)

    return (
        <Link onMouseEnter={() => prefetchNetwork(networkId)} to={`./${networkId}`}>
            más...
        </Link>
    )
}

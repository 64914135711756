import './api'

import { DescendantRoutes, RoutesType } from '../../Router/DescendantRoutes'

import { Network } from './Components/Network'
import { NetworkStats } from './Components/NetworkStats'
import { NetworksManage } from './Manage/NetworksManage'
import { PermissionDenied } from '../PermissionDenied/PermissionDenied'
import { Schedule } from './Components/Schedule'

export const NetworksRoutes = () => {
    const routes: RoutesType = [
        {
            path: '/',
            role: 'admin',
            element: <NetworksManage />,
            exitRoute: <PermissionDenied />,
        },
        {
            path: ':id',
            role: 'admin',
            element: <Network />,
            exitRoute: <PermissionDenied />,
        },
        {
            path: 'schedules/:id',
            role: 'admin',
            element: <Schedule />,
            exitRoute: <PermissionDenied />,
        },
        {
            path: ':id/stats',
            role: 'admin',
            element: <NetworkStats />,
            exitRoute: <PermissionDenied />,
        },
    ]
    return <DescendantRoutes propsElements={routes} />
}

import { FormEvent, useState } from 'react'
import { FrequencyDTO, NewFrequencyDTO, addEditFrequency } from '../api'
import { Title } from '../../../Components/Title'
import { useUIContext } from '../../../Context/UIContext'
import { useQueryClient } from 'react-query'
import { Modal, ModalProps } from '../../../Components/Modal'
import DayJS from '../../../Helpers/DayJS'

export const newFrequencyData: NewFrequencyDTO = {
    networkId: '',
    title: 'Título de la frecuencia',
    description:
        'Agregue una descripción en inglés y recuerde que ésta debe ser incluida en las traducciones del LXP junto a la traducción del título de la frecuencia.',
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
}
const weekDays = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
] as const

export const AddEditFrequency = ({
    frequency,
    onClose,
    ...modalProps
}: ModalProps & { frequency: FrequencyDTO | NewFrequencyDTO }) => {
    const queryClient = useQueryClient()
    const { showError } = useUIContext()
    const [data, setData] = useState(frequency)

    const isEditing = 'id' in frequency

    const onSubmit = (event?: FormEvent<HTMLFormElement>) => {
        event?.preventDefault()

        addEditFrequency(data)
            .catch(showError)
            .then(onClose)
            .finally(() => queryClient.invalidateQueries(['Network', data.networkId]))
    }

    return (
        <Modal
            {...modalProps}
            onClose={onClose}
            onConfirm={() => onSubmit()}
            onCancel={() => onClose()}
        >
            <main>
                <Title
                    title={`${isEditing ? 'Editar' : 'Agregar'} frecuencia`}
                    subtitle={`_Network ID: ${data.networkId}`}
                />
                <form onSubmit={onSubmit}>
                    <label>
                        Título de la frecuencia en inglés:
                        <input
                            type="text"
                            name="title"
                            id="title"
                            value={data.title}
                            onChange={({ target: { value, name } }) =>
                                setData((prev) => ({ ...prev, [name]: value }))
                            }
                        />
                    </label>
                    <label>
                        Agregue una descripción de la red:
                        <textarea
                            name="description"
                            id="description"
                            rows={6}
                            value={data.description}
                            onChange={({ target: { value, name } }) =>
                                setData((prev) => ({ ...prev, [name]: value }))
                            }
                        />
                    </label>
                    <br />
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(7, 1fr)',
                            gridTemplateRows: '20px 35px',
                        }}
                    >
                        <span style={{ gridColumn: '1 / -1' }}>Días de cursado:</span>
                        {weekDays.map((day, idx) => (
                            <label key={day} style={{ textAlign: 'center', margin: 0 }}>
                                {DayJS().day(idx).format('ddd')}
                                <input
                                    type="checkbox"
                                    name={day}
                                    id={day}
                                    checked={data[day]}
                                    onChange={({ target: { name, checked } }) =>
                                        setData((prev) => ({ ...prev, [name]: checked }))
                                    }
                                />
                            </label>
                        ))}
                    </div>
                </form>
            </main>
        </Modal>
    )
}

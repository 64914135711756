import { FocusEvent, useState } from 'react'
import { ScheduleWithQuotasDTO, editCohort } from '../api'

import { AddCohort } from './AddCohort'
import { Column } from 'react-table'
import DayJS from '../../../Helpers/DayJS'
import { Link } from 'react-router-dom'
import { SliderCheckbox } from '../../../Components/SliderCheckbox'
import { Table } from '../../../Components/Table'
import { Title } from '../../../Components/Title'
import { useQueryClient } from 'react-query'
import { useUIContext } from '../../../Context/UIContext'

export const CohortsList = (props: ScheduleWithQuotasDTO & { scheduleId: string }) => {
    const { showError } = useUIContext()
    const queryClient = useQueryClient()
    const [isOpen, setIsOpen] = useState(false)

    const { quotas } = props

    const startSchedule = props.quotas.length
        ? DayJS(props.quotas.at(-1)?.date)
              .add(7, 'days')
              .toDate()
        : DayJS.utc()
              .add(1, 'day')
              .hour(props.from ?? 0)
              .minute(0)
              .second(0)
              .toDate()

    const columns: Column<ScheduleWithQuotasDTO['quotas'][number]>[] = [
        {
            Header: 'ID de la Cohorte',
            accessor: 'id',
        },
        {
            id: 'date',
            Header: 'Fecha de inicio',
            accessor: ({ date }) => DayJS(date).format('LLLL'),
        },
        {
            id: 'Ocupación',
            Header: 'Ocupación',
            accessor: ({ limit, _count: { profiles } }) => {
                const percentage = (profiles ? (profiles * 100) / limit : 0).toFixed(2)

                return (
                    <label style={{ display: 'inline' }}>
                        <progress
                            max={100}
                            value={percentage}
                            style={{ marginRight: 20, width: 100 }}
                            title={`${percentage}% de ocupación`}
                        />
                        {percentage}%
                    </label>
                )
            },
        },
        {
            id: '_count',
            Header: 'Ocupados',
            accessor: ({ _count }) => `${_count.profiles}`,
        },
        {
            id: 'limit',
            Header: 'Limite',
            accessor: ({ id, limit, courseId }) => (
                <Limit id={id} limit={limit} courseId={courseId} />
            ),
        },

        {
            id: 'Mostrar',
            Header: 'Mostrar',
            accessor: ({ id, courseId, active }) => (
                <SliderCheckbox
                    checked={active}
                    onChange={() =>
                        editCohort({ id, data: { active: !active } })
                            .catch(showError)
                            .finally(() => queryClient.invalidateQueries(['Schedule', courseId]))
                    }
                />
            ),
        },
        {
            id: 'options',
            Header: 'Opciones',
            accessor: ({ id }) => <Link to={`/cohorts/${id}/staff`}>más...</Link>,
        },
    ]

    return (
        <section>
            <Title title="Cohortes">
                <button className="action" onClick={() => setIsOpen(true)}>
                    Agregar Cohorte
                </button>
            </Title>

            <Table data={quotas} columns={columns} canDownloadCsv={false} />

            <AddCohort
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                courseId={props.scheduleId}
                date={startSchedule}
            />
        </section>
    )
}

const Limit = ({ id, limit, courseId }: { id: string; limit: number; courseId: string }) => {
    const { showError } = useUIContext()
    const queryClient = useQueryClient()
    const update = ({
        target: {
            validity: { valid },
            defaultValue,
            value,
        },
    }: FocusEvent<HTMLInputElement, Element>) => {
        if (!valid || value === defaultValue) return
        editCohort({ id, data: { limit: +value } })
            .catch(showError)
            .finally(() => queryClient.invalidateQueries(['Schedule', courseId]))
    }

    return (
        <input
            type="number"
            name="limit"
            id="limit"
            defaultValue={limit}
            style={{ width: '8ch', textAlign: 'center' }}
            onBlur={update}
        />
    )
}

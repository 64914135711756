import { useQuery, useQueryClient } from 'react-query'
import { getNetworkById } from '../api'

export const useNetwork = (networkId?: Parameters<typeof getNetworkById>[number]) => {
    const queryClient = useQueryClient()
    const queryKey = ['Network', networkId]

    const prefetch = (networkId: string) =>
        queryClient.prefetchQuery({ queryKey, queryFn: () => getNetworkById(networkId) })

    const { data: network, ...rest } = useQuery({
        queryKey,
        queryFn: () => getNetworkById(`${networkId}`),
        staleTime: 10 * 60 * 1000,
        enabled: !!networkId,
    })

    return { network, prefetch, ...rest }
}

import React, { ReactNode } from 'react'

import { Editable } from './Editable'
import { Icon } from './Icon'
import Styles from './Title.module.css'
import { copyToClipboard } from '../Helpers/utilities'
import { useUIContext } from '../Context/UIContext'

export const Title = ({ image, title, subtitle, children, style, onEdit }: Props) => {
    const textTitle =
        typeof title === 'string' ? (
            <h1>
                <Editable onEdit={onEdit}>{title}</Editable>
            </h1>
        ) : undefined
    return (
        <div className={Styles.title} style={style}>
            {image && (
                <div>
                    <img src={image} alt="" className={Styles.image} />
                </div>
            )}
            <div className={Styles.label}>
                {typeof title === 'string' ? textTitle : title}
                {subtitle && typeof subtitle === 'string' && <SubTitle subtitle={subtitle} />}
                {subtitle &&
                    Array.isArray(subtitle) &&
                    subtitle.map((item) => <SubTitle subtitle={item} key={item} />)}
            </div>
            <div className={Styles.children}>{children}</div>
        </div>
    )
}

const SubTitle = ({ subtitle }: { subtitle: string }) => {
    const { showToast } = useUIContext()

    const handleClick = () => {
        if (subtitle.includes(':')) {
            const [key, value] = subtitle.split(':')
            copyToClipboard(value.trim())
            showToast(`${key} copiado al portapapeles`, 'info')
        } else
            copyToClipboard(subtitle).then(() => showToast('Texto copiado al portapapeles', 'info'))
    }

    if (subtitle.startsWith('_')) return <div>{subtitle.slice(1)}</div>
    else
        return (
            <div
                onClick={() => handleClick()}
                onKeyDown={() => handleClick()}
                role="button"
                tabIndex={0}
                className={Styles.subTitle}
            >
                {subtitle}
                <Icon style={{ marginLeft: '3px', minWidth: '3%' }} type="clipboard" size="small" />
            </div>
        )
}

type Props = {
    subtitle?: string | string[]
    children?: ReactNode
    image?: string
    style?: React.CSSProperties
} & (
    | {
          title: string
          // eslint-disable-next-line no-unused-vars
          onEdit?: (text: string) => unknown
      }
    | { title: ReactNode; onEdit?: never }
)

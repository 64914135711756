/* eslint-disable no-undef */
import { UseQueryOptions, useQuery, useQueryClient } from 'react-query'
import { getSchedule } from '../api'

export const useSchedule = ({ scheduleId, ...options }: Props) => {
    const queryClient = useQueryClient()
    const queryFn = () => getSchedule(scheduleId)
    const queryKey = ['Schedule', scheduleId]

    const prefetch = (turnId: string) =>
        queryClient.prefetchQuery({ queryKey, queryFn: () => getSchedule(turnId) })

    const { data: schedule, ...rest } = useQuery({
        queryFn,
        queryKey,
        staleTime: 1000 * 60 * 5,
        ...options,
    })
    return { schedule, prefetch, ...rest }
}

type TData = Awaited<ReturnType<typeof getSchedule>>
type Props = { scheduleId: string } & UseQueryOptions<TData, unknown, TData>

import { FormEvent, useState } from 'react'
import { Title } from '../../../Components/Title'
import { EditNetworkDTO, NewNetworkDTO, addEditNetwork } from '../api'
import { useUIContext } from '../../../Context/UIContext'
import { useQueryClient } from 'react-query'
import { Modal, ModalProps } from '../../../Components/Modal'

export const newNetworkData: NewNetworkDTO = {
    title: 'Título en Inglés',
    contentType: 'Tipo de contenido',
}

export const AddEditNetwork = ({
    network,
    onClose,
    ...modalProps
}: ModalProps & { network: EditNetworkDTO | NewNetworkDTO }) => {
    const queryClient = useQueryClient()
    const { showError } = useUIContext()
    const [data, setNetwork] = useState(network)
    const isEditing = 'id' in network

    const onSubmit = (event?: FormEvent<HTMLFormElement>) => {
        event?.preventDefault()
        addEditNetwork(data)
            .catch(showError)
            .then(onClose)
            .finally(() =>
                queryClient.invalidateQueries('id' in data ? ['Network', data.id] : ['Networks']),
            )
    }

    return (
        <Modal {...modalProps} onClose={onClose} onCancel={onClose} onConfirm={() => onSubmit()}>
            <main>
                <Title title={`${isEditing ? 'Editar' : 'Agregar'} Red`} />
                <form onSubmit={onSubmit}>
                    <label>
                        Título
                        <input
                            id="title"
                            type="text"
                            name="title"
                            required={true}
                            value={data.title}
                            onChange={({ target: { value, name } }) =>
                                setNetwork((prev) => ({ ...prev, [name]: value }))
                            }
                        />
                    </label>

                    <label>
                        Tipo de Contenido
                        <input
                            id="contentType"
                            type="text"
                            name="contentType"
                            required={true}
                            value={data.contentType}
                            onChange={({ target: { value, name } }) =>
                                setNetwork((prev) => ({ ...prev, [name]: value }))
                            }
                        />
                    </label>
                </form>
            </main>
        </Modal>
    )
}

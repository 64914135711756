import { capitalize } from '../../Helpers/formatters'
import { App } from '../../AppConfig'
import Axios from 'axios'

const Api = Axios.create({
    baseURL: `${App.APIOnline}/v2`,
    headers: {
        'access-token': localStorage.getItem('token') || '',
    },
})

export const searchProducts = (limit = 100, skip: number = 0, search: string = '') =>
    Api.get<GetProductsList>(`/product/products?limit=${limit}&skip=${skip}&search=${search}`).then(
        ({ data }) =>
            data.data.products.map((item) => ({
                ...item,
                name: `${capitalize(item.name || '')}`,
            })),
    )

export const fetchProduct = (id: string) =>
    Api.get<GetProduct>(`/product/${id}`).then(({ data }) => data.data)

export const createProduct = (data: Partial<ProductFromApi>) =>
    Api.post<CreateProduct>(`/product`, data)

export const updateProduct = (id: string, data: Partial<ProductFromApi>) =>
    Api.patch<PatchProduct>(`/product/${id}`, data)

export interface GetProduct {
    data: {
        product: ProductFromApi
        message: string
    }
}

export interface DeleteProduct {
    data: ProductFromApi
    message: string
}

export interface PatchProduct {
    data: ProductFromApi
    message: string
}

export interface CreateProduct {
    data: ProductFromApi
    message: string
}

export interface GetProductsList {
    data: {
        products: ProductFromApi[]
        totalCount: number
    }
}

export interface UTMParameters {
    utm_source: string
    utm_medium: string
}

export interface UTM {
    values: UTMParameters
    date: string
}

export interface ProductFromApi {
    _id: string
    currency: string
    installments: number
    name: string
    pipelineID: number
    price: number
    tag: string
    searchValue: string
    country: string
    startDate?: string
    endDate?: string
    owner: string
    isActive: boolean
}

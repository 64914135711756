import { UsersRoles } from './Interfaces/users'

export const ENV =
    location.origin.includes('//localhost') || location.origin.includes('//1')
        ? 'local'
        : location.origin.includes('//develop.')
        ? 'develop'
        : location.origin.includes('//demo.')
        ? 'demo'
        : location.origin.includes('//backoffice.')
        ? 'production'
        : 'unknown'

export const App: AppConfig = {
    userRoles: ['admin', 'director', 'professor', 'tutor', 'student', 'temporal'],
    firebaseConfig: {
        apiKey: `${process.env.REACT_APP_API_KEY}`,
        authDomain: `${process.env.REACT_APP_AUTH_DOMAIN}`,
        databaseURL: `${process.env.REACT_APP_DATABASE_URL}`,
        projectId: `${process.env.REACT_APP_PROJECT_ID}`,
        storageBucket: `${process.env.REACT_APP_STORAGE_BUCKET}`,
        messagingSenderId: `${process.env.REACT_APP_MESSAGING_SENDER_ID}`,
        appId: `${process.env.APP_ID}`,
    },
    API: `${process.env.REACT_APP_API}`,
    APIOnline: `${process.env.REACT_APP_API_ONLINE}`,
    APISignup: `${process.env.REACT_APP_API_ACTIVATION}`,
    DemoSite: `${process.env.REACT_APP_DEMO_SITE}`,
}

interface AppConfig {
    userRoles: UsersRoles[]
    firebaseConfig: {
        apiKey: string
        authDomain: string
        databaseURL: string
        projectId: string
        storageBucket: string
        messagingSenderId: string
        appId: string
    }
    API: string
    APIOnline: string
    APISignup: string
    DemoSite: string
}

export const ROLES = [
    {
        role: 'admin',
        title: 'Admin',
        class: 'action',
    },
    {
        role: 'director',
        title: 'Director',
        class: 'egg',
    },
    {
        role: 'professor',
        title: 'Coach',
        class: 'success',
    },
    {
        role: 'tutor',
        title: 'Profesor',
        class: 'primary',
    },
    {
        role: 'student',
        title: 'Estudiante',
        class: 'info',
    },
    {
        role: 'temporal',
        title: 'Temporal',
        class: 'error',
    },
]

export const quitReasons = [
    'Perfil Inhabilitado desde el BackOffice',
    'No le gusta la metodología',
    'No le gusta contenido',
    'Problemas de ingreso a la plataforma',
    'No dio motivos',
    'Problemas técnicos usuario',
    'Horarios de trabajo/facultad solapados',
    'Falta de tiempo',
    'No le gusta la programación',
    'Razones personales',
    'Info no disponible',
    'Cambio de edición',
    'Problemas económicos',
    'Baja Masiva',
    'Inconvenientes con el coach/profesor',
    'BAJA POR INACTIVIDAD',
    'Falta de pago',
    'Fin por contrato cliente',
    'Finalizó el curso normalmente ',
]

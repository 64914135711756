export const capitalize = (text?: string) => `${text}`.trim().replace(/^\w/, (c) => c.toUpperCase())

export const toCurrency = (value: number) => {
    return new Intl.NumberFormat('es-AR', {
        style: 'currency',
        currency: 'ARS',
    }).format(value)
}

export function toPhoneNumber(phone: string) {
    const cleaned = `${phone}`.replace(/\D/g, '')
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    return match ? `(${match[1]}) ${match[2]}-${match[3]}` : phone
}

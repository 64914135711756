import { ChartComponent, ChartData } from './ChartComponent'

import { ProfilesStatsDTO } from '../Pages/Cohort/api'
import Styles from './ProfilesStats.module.css'
import { useState } from 'react'

export const ProfilesStats = ({ stats }: { stats?: ProfilesStatsDTO }) => {
    const [view, setView] = useState<Views>('Acquisition')

    if (!stats) return null

    const View = views[view]

    return (
        <section className={Styles.container}>
            <div className={Styles.students}>
                <b>Estudiantes: </b> {stats.items}
            </div>
            <nav>
                <button
                    className={view === 'Acquisition' ? Styles.active : Styles.inactive}
                    onClick={() => setView('Acquisition')}
                >
                    Adquisición
                </button>

                <button
                    className={view === 'Course' ? Styles.active : Styles.inactive}
                    onClick={() => setView('Course')}
                >
                    Estadísticas de Cursado
                </button>

                <button
                    className={view === 'Demographic' ? Styles.active : Styles.inactive}
                    onClick={() => setView('Demographic')}
                >
                    Demografía
                </button>

                <button
                    className={view === 'Ages' ? Styles.active : Styles.inactive}
                    onClick={() => setView('Ages')}
                >
                    Rango etario
                </button>
                <button
                    className={view === 'Interest' ? Styles.active : Styles.inactive}
                    onClick={() => setView('Interest')}
                >
                    Intereses
                </button>
            </nav>
            <View {...stats} />
        </section>
    )
}

const Acquisition = (stats: ProfilesStatsDTO) => {
    const sources: ChartData = {
        chartType: 'PieChart',
        data: stats.sources,
        options: {
            title: 'Origen de estudiante',
        },
    }

    const referrer: ChartData = {
        chartType: 'PieChart',
        data: stats.referrers,
        options: {
            title: 'Referentes',
        },
    }

    const institution: ChartData = {
        chartType: 'PieChart',
        data: stats.institution,
        options: {
            title: 'Institución',
        },
    }

    const acquisitions: ChartData = {
        chartType: 'PieChart',
        data: stats.acquisition,
        options: {
            title: 'Adquisición',
        },
    }

    return (
        <>
            <div className="column-2">
                <ChartComponent {...sources} />
                <ChartComponent {...referrer} />
            </div>
            <div className="column-2">
                <ChartComponent {...institution} />
                <ChartComponent {...acquisitions} />
            </div>
        </>
    )
}

const Course = (stats: ProfilesStatsDTO) => {
    const status: ChartData = {
        chartType: 'PieChart',
        data: stats.status,
        options: {
            title: 'Estado de cursado',
        },
    }

    const dates: ChartData = {
        chartType: 'PieChart',
        data: stats.dates,
        options: {
            title: 'Fecha de inicio',
        },
    }

    const days: ChartData = {
        chartType: 'PieChart',
        data: stats.days,
        options: {
            title: 'Días inicio en la semana',
        },
    }

    const timesMentor: ChartData = {
        chartType: 'PieChart',
        data: stats.timesMentor,
        options: {
            title: 'Cantidad de veces como Mentor',
        },
    }

    return (
        <>
            <div className="column-2">
                <ChartComponent {...status} />
                <ChartComponent {...dates} />
            </div>
            <div className="column-2">
                <ChartComponent {...days} />
                <ChartComponent {...timesMentor} />
            </div>
        </>
    )
}

const Interest = (stats: ProfilesStatsDTO) => {
    const employment: ChartData = {
        chartType: 'PieChart',
        data: stats.employment,
        options: {
            title: 'Situación laboral',
        },
    }

    const goal: ChartData = {
        chartType: 'PieChart',
        data: stats.goal,
        options: {
            title: 'Objetivo',
        },
    }

    const motivation: ChartData = {
        chartType: 'PieChart',
        data: stats.motivation,
        options: {
            title: 'Motivación',
        },
    }

    const technologyRelation: ChartData = {
        chartType: 'PieChart',
        data: stats.technologyRelation,
        options: {
            title: 'Relación con la tecnología',
        },
    }

    return (
        <>
            <div className="column-2">
                <ChartComponent {...employment} />
                <ChartComponent {...goal} />
            </div>
            <div className="column-2">
                <ChartComponent {...motivation} />
                <ChartComponent {...technologyRelation} />
            </div>
        </>
    )
}

const Demographic = (stats: ProfilesStatsDTO) => {
    const countries: ChartData = {
        chartType: 'BarChart',
        headers: ['-', 'Cantidad'],
        data: stats.countries,
        options: {
            title: 'Países',
            colors: ['#ff9900'],
        },
    }

    const provinces: ChartData = {
        chartType: 'BarChart',
        data: stats.provinces,
        headers: ['-', 'Cantidad'],
        options: {
            title: 'Provincias',
            colors: ['#dc3912'],
        },
    }

    return (
        <>
            <ChartComponent {...countries} />
            <ChartComponent {...provinces} />
        </>
    )
}

const Ages = (stats: ProfilesStatsDTO) => {
    const ages: ChartData = {
        chartType: 'PieChart',
        data: stats.ages,
        options: {
            title: 'Edades',
        },
    }

    const genres: ChartData = {
        chartType: 'PieChart',
        data: stats.genres,
        options: {
            title: 'Sexo',
        },
    }

    return (
        <>
            <ChartComponent {...ages} />
            <ChartComponent {...genres} />
        </>
    )
}

type Views = keyof typeof views

const views = {
    Acquisition,
    Interest,
    Demographic,
    Ages,
    Course,
}

import { useQuery } from 'react-query'
import { getNetworks } from '../api'

export const useNetworks = () => {
    const queryKey = ['Networks']
    const { data: networks, ...rest } = useQuery({
        queryKey,
        queryFn: () => getNetworks(),
        staleTime: 10 * 60 * 1000,
    })
    return { networks, ...rest }
}

import React from 'react'

import Styles from './SliderCheckbox.module.css'

export const SliderCheckbox = ({ title, id, checked, onChange }: Props) => (
    <label className={Styles.switch} htmlFor={id} title={title}>
        <input
            type="checkbox"
            id={id}
            checked={checked}
            onChange={({ target: { checked } }) => onChange(checked)}
        />
        <span className={`${Styles.slider} ${Styles.round}`} />
    </label>
)

interface Props {
    title?: string
    id?: string
    checked: boolean
    // eslint-disable-next-line no-unused-vars
    onChange: (checked: boolean) => void
}

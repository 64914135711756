import React, { useEffect, lazy } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AuthRoles } from '../Interfaces/users'
import { App } from '../AppConfig'

// Context
import { useAuthContext } from '../Context/AuthContext'
import { ProductsRoutesV1 } from '../Pages/ProductsV1/ProductsRoutesV1'
import { NetworksRoutes } from '../Pages/Networks/NetworksRoutes'

// Components
const PermissionDenied = lazy(() => import('../Pages/PermissionDenied/PermissionDenied'))
const AsideMenu = lazy(() => import('../Pages/AsideMenu/AsideMenu'))
const UserRoutes = lazy(() => import('../Pages/Users/UserRoutes'))
const CohortsRoutes = lazy(() => import('../Pages//Cohort/CohortsRoutes'))
const Home = lazy(() => import('../Pages/Home/Home'))
const Login = lazy(() => import('../Pages/Login/Login'))
const Migrations = lazy(() => import('../Pages/Migrations/Migrations'))
const LeadsRoutes = lazy(() => import('../Pages/Leads/LeadsRoutes'))
const ProductsRoutes = lazy(() => import('../Pages/Products/ProductsRoutes'))
const HqRoutes = lazy(() => import('../Pages/Hq/HqRoutes'))
const TeamRoutes = lazy(() => import('../Pages/Team/TeamRoutes'))
const PaymentsRoutes = lazy(() => import('../Pages/Payment/PaymentsRoutes'))
const SettingsRoutes = lazy(() => import('../Pages/Settings/SettingsRoutes'))

export const AuthRole = () => {
    const { me, role, signIn, tokenIsValid } = useAuthContext()

    /** Login from localStorage saved token */
    useEffect(() => {
        const token = localStorage.getItem('token')
        if (token && !me && tokenIsValid(token)) signIn({ token })
    }, [me, signIn, tokenIsValid])

    const hasAuth = (minRouteRole: AuthRoles) => {
        const { userRoles } = App
        const userAuthLevel = role ? userRoles.indexOf(role) : -1
        const routeAuthLevel = minRouteRole !== 'guest' ? userRoles.indexOf(minRouteRole) : Infinity
        const hasPrivilegies = role && userAuthLevel <= routeAuthLevel
        return hasPrivilegies
    }
    return { hasAuth, me }
}

export const AppRouter = () => {
    const { hasAuth, me } = AuthRole()
    return (
        <BrowserRouter>
            <AsideMenu />
            <main className={`main ${!me ? 'login' : ''}`}>
                <Routes>
                    <Route path="/" element={hasAuth('guest') ? <Home /> : <PermissionDenied />} />

                    <Route
                        path="cohorts/*"
                        element={hasAuth('professor') ? <CohortsRoutes /> : <PermissionDenied />}
                    />

                    <Route
                        path="users/*"
                        element={hasAuth('professor') ? <UserRoutes /> : <PermissionDenied />}
                    />

                    <Route
                        path="/migrations"
                        element={hasAuth('admin') ? <Migrations /> : <PermissionDenied />}
                    />

                    <Route
                        path="/leads/*"
                        element={hasAuth('admin') ? <LeadsRoutes /> : <PermissionDenied />}
                    />

                    <Route
                        path="/products/*"
                        element={hasAuth('admin') ? <ProductsRoutes /> : <PermissionDenied />}
                    />
                    <Route
                        path="/productsV1/*"
                        element={hasAuth('admin') ? <ProductsRoutesV1 /> : <PermissionDenied />}
                    />
                    <Route
                        path="/networks/*"
                        element={hasAuth('admin') ? <NetworksRoutes /> : <PermissionDenied />}
                    />

                    <Route
                        path="hqs/*"
                        element={hasAuth('professor') ? <HqRoutes /> : <PermissionDenied />}
                    />

                    <Route
                        path="/team/:id/*"
                        element={hasAuth('professor') ? <TeamRoutes /> : <PermissionDenied />}
                    />

                    <Route
                        path="/payments/*"
                        element={hasAuth('admin') ? <PaymentsRoutes /> : <PermissionDenied />}
                    />
                    <Route
                        path="/settings/*"
                        element={hasAuth('admin') ? <SettingsRoutes /> : <PermissionDenied />}
                    />

                    <Route path="/login" element={<Login />} />
                </Routes>
            </main>
        </BrowserRouter>
    )
}

import { AddEditFrequency, newFrequencyData } from './AddEditFrequency'
import { useNavigate, useParams } from 'react-router-dom'

import { AddEditNetwork } from './AddEditNetwork'
import { Frequency } from './Frequency'
import { Icon } from '../../../Components/Icon'
import { Title } from '../../../Components/Title'
import { capitalize } from '../../../Helpers/formatters'
import { useNetwork } from '../Hooks/useNetwork'
import { useState } from 'react'

export const Network = () => {
    const { id = '' } = useParams()
    const [isOpen, setIsOpen] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const navigate = useNavigate()

    const { network, isLoading } = useNetwork(id)

    if (isLoading) return <p>Cargando...</p>

    if (!network) return <p className="danger">Red no encontrada!</p>
    const { title, contentType } = network

    return (
        <>
            <Title
                title={`Redes - ${capitalize(network?.title)}`}
                subtitle={[`ID: ${id}`, `Tipo de contenido: ${network?.contentType}`]}
            >
                <button className="outlined" onClick={() => setIsEditing(true)}>
                    Editar Red
                </button>
                <button className="action" onClick={() => setIsOpen(true)}>
                    Agregar Frecuencia
                </button>
                <Icon
                    type="stats"
                    className="outlined"
                    onClick={() => navigate(`../${id}/stats`)}
                />
            </Title>

            {network?.frequencies.map((frequency) => (
                <Frequency {...frequency} key={frequency.id} />
            ))}
            {!network?.frequencies.length && <p>No hay frecuencias configuradas para esta red.</p>}

            <AddEditFrequency
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                frequency={{ ...newFrequencyData, networkId: id }}
            />

            <AddEditNetwork
                isOpen={isEditing}
                onClose={() => setIsEditing(false)}
                network={{ id, title, contentType }}
            />
        </>
    )
}

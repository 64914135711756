import Axios from 'axios'
import React, { useState } from 'react'
import { Icon } from '../../../../Components/Icon'
import { TextToClipboard } from '../../../../Components/TextToClipboard'
import { useUIContext } from '../../../../Context/UIContext'
import { signInLikeUser, getWidgetToken } from '../../api'
import { ProfileData } from '../ProfileManage'

import Styles from '../ProfileManage.module.css'

export const ProfileLogin = ({
    _userId,
    _id: profileId,
    displayName,
    role,
    email,
    isAdmin,
}: ProfileData & { isAdmin: boolean }) => {
    const { showToast, showError } = useUIContext()

    const [lxpCode, setLxpCode] = useState<string>()

    const userID = _userId._id
    const openLXP = (code: string) =>
        window.open(`${process.env.REACT_APP_LXP_URL}/login/admin?code=${code}`)

    const handleOpenPlatform = () => {
        if (!userID) return
        signInLikeUser(userID).catch(({ response }) => showToast(response.data.message, 'error'))
    }

    const handleOpenWidget = () => {
        if (!profileId) return
        getWidgetToken(profileId)
            .then(({ token, profile }) => {
                if (profile.role === 'student')
                    window.open(
                        `${process.env.REACT_APP_WIDGET_URL}?token=${token}`,
                        profileId,
                        `
                        popup=yes,
                        left=100,
                        top=100,
                        width=350,
                        height=550,
                         `,
                    )
                else showToast(`El usuario no es estudiante y no tiene acceso el widget.`, 'info')
            })
            .catch(({ response }) => showToast(response.data.message, 'error'))
    }

    const handleOpenLXP = () => {
        lxpCode
            ? openLXP(lxpCode)
            : Axios.post<LXPLoginResponse>(
                  'v1/lxp/backoffice/login',
                  { email },
                  {
                      baseURL: `${process.env.REACT_APP_WIDGET_API}`,
                      headers: {
                          authorization: Axios.defaults.headers.common['access-token'],
                      },
                  },
              )
                  .then(({ data: { data } }) => {
                      setLxpCode(data.codigo)
                      openLXP(data.codigo)
                  })
                  .catch((err) => showError(err))
    }

    const envLXP = !!process.env.REACT_APP_WIDGET_API && !!process.env.REACT_APP_LXP_URL
    const envWidget = !!process.env.REACT_APP_WIDGET_URL

    if (!isAdmin)
        return (
            <section className={Styles.LoginSection}>
                ⛔ No tiene permisos para realizar esta acción.
            </section>
        )

    return (
        <>
            <section className={Styles.LoginSection}>
                {role === 'student' && (
                    <>
                        <Card
                            label="LXP"
                            type="login"
                            onClick={handleOpenLXP}
                            title={`Abrir LXP cómo ${displayName}`}
                            disabled={!envLXP}
                        />

                        <Card
                            label="Widget"
                            type="login"
                            onClick={handleOpenWidget}
                            title={`Abrir widget cómo ${displayName}`}
                            disabled={!envWidget}
                        />
                    </>
                )}
                <Card
                    label="Plataforma"
                    type="login"
                    onClick={handleOpenPlatform}
                    title={`Abrir plataforma cómo ${displayName}`}
                />
            </section>
            <section style={{ color: 'var(--color-success)', textAlign: 'center' }}>
                {lxpCode && (
                    <p>
                        Código de acceso a LXP: <TextToClipboard text={lxpCode} />
                    </p>
                )}
                {!envLXP && (
                    <div>Add REACT_APP_WIDGET_API and REACT_APP_LXP_URL to enable LXP login.</div>
                )}
                {!envWidget && <div>Add REACT_APP_WIDGET_URL to enable Widget login.</div>}
            </section>
        </>
    )
}

const Card = ({ label, title, onClick, disabled = false, ...rest }: CardProps) => (
    <button
        style={{
            width: 120,
            height: 80,
            border: '1px solid',
            display: 'grid',
            placeContent: 'center',
            padding: 20,
            gap: 20,
        }}
        onClick={onClick}
        title={title}
        disabled={disabled}
    >
        <Icon {...rest} style={{ margin: '0 auto' }} size="large" />
        <span>{label}</span>
    </button>
)

type IconProps = Parameters<typeof Icon>[0]

interface CardProps extends IconProps {
    label: string
    disabled?: boolean
    onClick?: () => void
}

interface LXPLoginResponse {
    data: {
        codigo: string
        message: string
        expirationDate: string
    }
    message: string
}

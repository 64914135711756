export const copyToClipboard = async (text: string) =>
    navigator.clipboard && window.isSecureContext && navigator.clipboard.writeText(text)

/**
 * Return a array with original array splitted in length parts
 * @param array
 * @param length a length of each part
 * @returns new array with array with the part
 */
export const arraySplitter = <T>(array: T[], length: number) => {
    const results = [] as typeof array[]
    const clone = structuredClone(array) as typeof array
    for (let index = 0, stop = array.length / length; index < stop; index++)
        results.push(clone.splice(0, length))
    return results
}

import { NetworksList } from '../Components/NetworksList'
import { Title } from '../../../Components/Title'
import { useState } from 'react'
import { AddEditNetwork, newNetworkData } from '../Components/AddEditNetwork'

export const NetworksManage = () => {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <div>
            <Title title="Redes">
                <button className="action" onClick={() => setIsOpen(true)}>
                    Agregar Red
                </button>
            </Title>

            <NetworksList />
            <AddEditNetwork
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                network={newNetworkData}
            />
        </div>
    )
}

import { FrequencyDTO, getSchedule } from '../api'

import { Column } from 'react-table'
import { Link } from 'react-router-dom'
import { Table } from '../../../Components/Table'
import { fromUTCToLocalRange } from '../../../Helpers/dateFormatters'
import { useQueries } from 'react-query'
import { useSchedule } from '../Hooks/useSchedule'

export const ScheduleList = ({ courses }: { courses: FrequencyDTO['courses'] }) => {
    const coursesQueries = useQueries(
        courses.map(({ id }) => ({
            queryKey: ['Schedule', id],
            queryFn: () => getSchedule(id),
            staleTime: 5 * 60 * 1000,
        })),
    )

    const columns: Column<FrequencyDTO['courses'][number]>[] = [
        {
            id: 'Curso',
            Header: 'Alias del curso',
            accessor: ({ id }) =>
                coursesQueries.find(({ data }) => data?.id === id)?.data?.alias ?? '...',
        },
        {
            id: 'Horario',
            Header: 'Horario',
            accessor: ({ from, to }) => fromUTCToLocalRange(from, to),
        },
        { Header: 'Institución', accessor: 'institution' },
        { Header: 'Mongo ID', accessor: 'mongoId' },

        {
            id: 'Cohortes',
            Header: 'Cohortes',
            accessor: ({ id }) =>
                coursesQueries.find(({ data }) => data?.id === id)?.data?.quotas.length ?? '...',
        },
        {
            Header: '',
            id: 'options',
            accessor: ({ id }) => (
                <div className="flex">
                    <SmartLink scheduleId={id} />
                </div>
            ),
        },
    ]

    if (!courses.length) return <p>No hay horarios configurados para ésta frecuencia.</p>
    return <Table data={courses} columns={columns} canDownloadCsv={false} />
}

const SmartLink = ({ scheduleId }: { scheduleId: string }) => {
    const { prefetch } = useSchedule({ scheduleId })

    return (
        <Link to={`../schedules/${scheduleId}`} onMouseEnter={() => prefetch(scheduleId)}>
            más...
        </Link>
    )
}

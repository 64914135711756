import { FormEvent, useState } from 'react'
import { Modal, ModalProps } from '../../../Components/Modal'
import { Title } from '../../../Components/Title'
import { EditScheduleDTO, NewScheduleDTO, addEditSchedule } from '../api'
import { useUIContext } from '../../../Context/UIContext'
import DayJS from '../../../Helpers/DayJS'
import { useQueryClient } from 'react-query'

export const newScheduleData = {
    mongoId: '',
    from: 13,
    to: 15,
    institution: 'Beca_Egg',
}

export const AddEditSchedule = ({
    schedule,
    onClose,
    ...modalProps
}: ModalProps & { schedule: NewScheduleDTO | EditScheduleDTO }) => {
    const queryClient = useQueryClient()
    const { showError } = useUIContext()
    const [data, setData] = useState(schedule)
    const isEditing = 'id' in schedule

    const onSubmit = (event?: FormEvent<HTMLFormElement>) => {
        event?.preventDefault()
        if (!data.mongoId) return showError('Complete los datos requeridos')

        addEditSchedule(data)
            .catch(showError)
            .then(onClose)
            .finally(() =>
                queryClient.invalidateQueries(
                    'id' in schedule ? ['Schedule', schedule.id] : ['Network', schedule.networkId],
                ),
            )
    }

    const today = DayJS.utc().hour(0).minute(0).second(0)

    return (
        <Modal
            {...modalProps}
            onClose={onClose}
            onConfirm={() => onSubmit()}
            onCancel={() => onClose()}
        >
            <main>
                <Title
                    title={`${isEditing ? 'Editar' : 'Agregar'} Horario`}
                    subtitle={[`Frecuencia ID: ${data.frequencyId}`]}
                ></Title>
                <form onSubmit={onSubmit}>
                    <label>
                        Mongo ID en el cuál se enrolarán los estudiantes:
                        <input
                            id="mongoId"
                            type="text"
                            name="mongoId"
                            required={true}
                            pattern="^[a-fA-F0-9]{24}$"
                            value={data.mongoId}
                            onChange={({ target: { name, value } }) =>
                                setData((prev) => ({ ...prev, [name]: value }))
                            }
                        />
                    </label>

                    <label>
                        Institución a la que los estudiantes pertenecen:
                        <input
                            id="institution"
                            type="text"
                            name="institution"
                            value={data.institution}
                            onChange={({ target: { name, value } }) =>
                                setData((prev) => ({ ...prev, [name]: value }))
                            }
                        />
                    </label>

                    <fieldset style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <legend>Horario de cursado en su zona horaria</legend>
                        <label>
                            Desde:
                            <input
                                type="time"
                                name="from"
                                id="from"
                                step={3600}
                                value={today.hour(data.from).local().format('HH:mm')}
                                onChange={({ target: { name, value } }) => {
                                    const [hours] = value.split(':')
                                    setData((prev) => ({
                                        ...prev,
                                        [name]: +DayJS()
                                            .hour(+hours)
                                            .minute(0)
                                            .utc()
                                            .format('HH'),
                                    }))
                                }}
                            />
                        </label>

                        <label>
                            Hasta:
                            <input
                                type="time"
                                name="to"
                                id="to"
                                step={3600}
                                value={today.hour(data.to).local().format('HH:mm')}
                                onChange={({ target: { name, value } }) => {
                                    const [hours] = value.split(':')
                                    setData((prev) => ({
                                        ...prev,
                                        [name]: +DayJS()
                                            .hour(+hours)
                                            .minute(0)
                                            .utc()
                                            .format('HH'),
                                    }))
                                }}
                            />
                        </label>
                    </fieldset>
                </form>
            </main>
        </Modal>
    )
}

import { FormEvent, useState } from 'react'
import { Title } from '../../../Components/Title'
import { NewCohort, CohortDTO, addCohort } from '../api'
import DayJS from 'dayjs'
import { useUIContext } from '../../../Context/UIContext'
import { useQueryClient } from 'react-query'
import { Modal, ModalProps } from '../../../Components/Modal'

const InitialQuota: Pick<CohortDTO, 'limit' | 'taken' | 'active'> = {
    limit: 1500,
    taken: 0,
    active: true,
}

const min = DayJS().add(1, 'day').hour(0).minute(0).second(0)

export const AddCohort = ({
    onClose,
    courseId,
    date,
    ...modalProps
}: ModalProps & { courseId: string; date: Date }) => {
    const queryClient = useQueryClient()
    const { showError } = useUIContext()
    const [data, setData] = useState({
        ...InitialQuota,
        courseId,
        date,
    })

    const onSubmit = (event?: FormEvent<HTMLFormElement>) => {
        event?.preventDefault()
        const newQuota: NewCohort = {
            ...data,
            date: DayJS(data.date).toISOString(),
        }
        addCohort(newQuota)
            .catch(showError)
            .then(onClose)
            .finally(() => queryClient.invalidateQueries(['Schedule', courseId]))
    }

    return (
        <Modal {...modalProps} onClose={onClose} onCancel={onClose} onConfirm={() => onSubmit()}>
            <main>
                <Title title="Agregar Red" />
                <form onSubmit={onSubmit}>
                    <label>
                        Fecha y hora de cursado en su zona horaria:
                        <input
                            id="date"
                            type="datetime-local"
                            name="date"
                            required={true}
                            value={DayJS(data.date).format('YYYY-MM-DDTHH:mm')}
                            min={min.format('YYYY-MM-DDTHH:mm')}
                            onChange={({ target: { value, name } }) =>
                                setData((prev) => ({ ...prev, [name]: DayJS(value).toDate() }))
                            }
                        />
                    </label>

                    <label>
                        Totales de cupos disponibles:
                        <input
                            id="limit"
                            type="number"
                            name="limit"
                            min={0}
                            max={1500}
                            required={true}
                            value={data.limit}
                            onChange={({ target: { value, name } }) =>
                                setData((prev) => ({ ...prev, [name]: +value }))
                            }
                        />
                    </label>

                    <label>
                        Mostrar ésta fecha a los usuarios
                        <select
                            id="active"
                            name="active"
                            defaultValue={data.active ? 1 : 0}
                            onChange={({ target: { value, name } }) =>
                                setData((prev) => ({ ...prev, [name]: !!+value }))
                            }
                        >
                            <option value={1}>Mostrar</option>
                            <option value={0}>Ocultar</option>
                        </select>
                    </label>
                </form>
            </main>
        </Modal>
    )
}

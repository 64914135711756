import { useState } from 'react'
import Modal from 'react-modal'
import { UseMutateFunction, useMutation, useQuery, useQueryClient } from 'react-query'
import { ROLES } from '../../../AppConfig'
import { Icon } from '../../../Components/Icon'
import { Tag } from '../../../Components/Tag'
import { Title } from '../../../Components/Title'
import { useUIContext } from '../../../Context/UIContext'
import { capitalize } from '../../../Helpers/formatters'
import {
    ProfileDayOfContentResponse,
    getProfileDayOfContent,
    getProfileDetails,
    updateProfileData,
    UpdateProfileDataParams,
    updateProfileDayOfContent,
} from '../api'
import DayJS from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import es from 'dayjs/locale/es'
import localizedFormat from 'dayjs/plugin/localizedFormat'
DayJS.extend(relativeTime)
DayJS.extend(localizedFormat)
DayJS.locale(es)

import Styles from './ProfileManage.module.css'
import { ProfileDetails } from './ProfileManager/ProfileDetails'
import { ProfileLogin } from './ProfileManager/ProfileLogin'
import { useRecent } from '../../../Hooks/useRecent'
import { ProfileActions } from './ProfileManager/ProfileActions'
import { ProfileTracking } from './ProfileManager/ProfileTracking'
import { useAuthContext } from '../../../Context/AuthContext'
import { UsersRoles } from '../../../Interfaces/users'
import { useTracking } from '../../../Hooks/useTracking'

export const ProfileManage = (props: ProfileManager) => {
    const { addTracking } = useTracking()
    const { saveHistory } = useRecent()
    const queryClient = useQueryClient()
    const { setProfileManage, showError, showToast } = useUIContext()
    const [view, setView] = useState<'Login' | 'Details' | 'Actions' | 'Tracking'>('Login')

    const { profileId, loginAs } = props ?? {}
    const isAdmin = loginAs === 'admin'

    const profileKey = ['getProfileDetails', profileId]
    const contentKey = ['getProfileDayOfContent', profileId]

    const { data, status } = useQuery(profileKey, () => getProfileDetails(`${profileId}`), {
        enabled: !!profileId,
    })

    data?.displayName &&
        saveHistory({
            title: `Perfil de ${data?.displayName}`,
            url: `/users/manage/${data._userId._id}`,
        })

    const { mutate } = useMutation(
        (params: Parameters<typeof updateProfileData>) => updateProfileData(...params),
        {
            onMutate: ([, data]) => {
                const updatedAt = new Date()
                const snapshot = queryClient.getQueryData<typeof data>(profileKey)
                snapshot &&
                    queryClient.setQueryData(profileKey, { ...snapshot, ...data, updatedAt })
                return snapshot
            },
            onError: (error, _, snapshot) => {
                showError(error)
                snapshot && queryClient.setQueryData(profileKey, snapshot)
            },
            onSettled: (_, __, variables, context) => {
                const [, payload] = variables
                const courseID = context?._classId?._id ?? ''
                const userID = context?._userId?._id ?? ''

                queryClient.invalidateQueries(profileKey)
                queryClient.invalidateQueries(['getAllUserProfiles', userID])

                if ('isExpertMentor' in payload) {
                    queryClient.invalidateQueries(['searchStudents', '', courseID])
                    queryClient.invalidateQueries([courseID, 'getExpertMentors'])
                }
            },
        },
    )

    const hasContent =
        !!profileId && data?._classId?.algorithmType === 'hca4' && data.role === 'student'

    const requireContentInfo =
        (hasContent && view === 'Details') ||
        (hasContent && view === 'Actions' && data.active === false)

    const { data: content } = useQuery(contentKey, () => getProfileDayOfContent(`${profileId}`), {
        enabled: requireContentInfo,
        staleTime: 1000 * 60 * 5,
        retry: 2,
        onError: () =>
            showToast(
                'No fué posible recuperar información sobre el contenido académico.',
                'error',
                5,
            ),
    })

    const { mutate: mutateContent } = useMutation(
        (params: Parameters<typeof updateProfileDayOfContent>) =>
            updateProfileDayOfContent(...params),
        {
            onMutate: ([{ newContentDay }]) => {
                const snapshot = queryClient.getQueryData<typeof content>(contentKey)
                snapshot &&
                    queryClient.setQueryData<typeof content>(contentKey, (prev) =>
                        prev ? { ...prev, day: newContentDay, description: 'Cargando...' } : prev,
                    )
                return snapshot
            },

            onSuccess: (data, [{ profileId, newContentDay }]) => {
                const error = data.day !== newContentDay

                if (!data.description) data.description = 'Descripción del contenido no disponible.'
                queryClient.setQueryData<typeof content>(contentKey, (prev) =>
                    prev ? { ...prev, ...data } : prev,
                )

                addTracking({
                    _id: profileId,
                    tracking: {
                        date: new Date().toISOString(),
                        type: 'modified',
                        message: !error
                            ? `Contenido modificado al día ${data.day}`
                            : `Error al modificar el contenido al día ${newContentDay}`,
                    },
                })
            },

            onError: (error, [data], snapshot) => {
                snapshot && queryClient.setQueryData(contentKey, snapshot)
                showToast('No fué posible actualizar el día de contenido académico.', 'error', 5)
                addTracking({
                    _id: data.profileId,
                    tracking: {
                        date: new Date().toISOString(),
                        type: 'modified',
                        message: `Error al modificar el contenido al día ${data.newContentDay}`,
                    },
                })
            },
        },
    )

    const { active, displayName, _classId, _hqId, createdAt, role, isExpertMentor } = data ?? {}

    const section =
        view === 'Details'
            ? 'Editar perfil'
            : view === 'Login'
            ? 'Iniciar sesión'
            : view === 'Actions' && active
            ? 'Pausar perfil'
            : view === 'Actions' && !active
            ? 'Reactivar perfil'
            : view === 'Tracking'
            ? 'Trazabilidad'
            : ''

    const courseName = capitalize(`${_classId?.name}`)
    const hqName = capitalize(`${_hqId?.name}`)
    const enrolledSince = DayJS(createdAt).fromNow()
    const hqAndCourse = `_${hqName}, ${courseName}, enrolado ${enrolledSince}`
    const rol = isExpertMentor ? 'Mentor Experto' : ROLES.find((item) => item.role === role)?.title
    const title = (
        <div
            style={{
                display: 'flex',
                justifyContent: 'flex-start',
                gap: '1em',
                alignItems: 'center',
            }}
        >
            <h1>{displayName}</h1> {rol}
            <Tag
                variant={active ? 'success' : 'error'}
                label={active ? 'Activo' : 'Pausado'}
                onClick={() => setView('Actions')}
            />
        </div>
    )
    const subtitle = [hqAndCourse, `Profile ID: ${data?._id}`, `Email: ${data?.email}`]

    status === 'loading' && <p>Cargando...</p>

    if (!profileId) return null

    return (
        <Modal
            isOpen={!!profileId}
            onRequestClose={() => setProfileManage(null)}
            style={{
                overlay: {
                    backgroundColor: '#000000bd',
                },
                content: {
                    width: 750,
                    height: 500,
                    inset: '50%',
                    transform: 'translate(-50%, -50%)',
                },
            }}
        >
            <header>
                <Title title={title} subtitle={subtitle} style={{ marginTop: 0 }}>
                    <nav className={Styles.sectionNav}>
                        <Icon
                            type="login"
                            title="Iniciar sesión"
                            className={view === 'Login' ? Styles.active : Styles.inactive}
                            onClick={() => setView('Login')}
                        />
                        <Icon
                            type="details"
                            title="Detalles"
                            className={view === 'Details' ? Styles.active : Styles.inactive}
                            onClick={() => setView('Details')}
                        />
                        <Icon
                            type="settings"
                            title=""
                            className={view === 'Actions' ? Styles.active : Styles.inactive}
                            onClick={() => setView('Actions')}
                        />
                        <Icon
                            type="trending"
                            title="Trazabilidad"
                            className={view === 'Tracking' ? Styles.active : Styles.inactive}
                            onClick={() => setView('Tracking')}
                        />
                    </nav>
                </Title>
            </header>
            <h3 style={{ margin: 0 }}>{section}</h3>
            <main className={Styles.content}>
                {data && (
                    <>
                        {view === 'Details' && (
                            <ProfileDetails
                                {...data}
                                mutate={mutate}
                                content={content}
                                mutateContent={mutateContent}
                            />
                        )}
                        {view === 'Login' && <ProfileLogin {...data} isAdmin={isAdmin} />}
                        {view === 'Actions' && (
                            <ProfileActions
                                {...data}
                                mutate={mutate}
                                content={content}
                                mutateContent={mutateContent}
                            />
                        )}
                        {view === 'Tracking' && (
                            <ProfileTracking
                                {...data}
                                mutate={mutate}
                                content={content}
                                mutateContent={mutateContent}
                            />
                        )}
                    </>
                )}
            </main>
            <footer>
                <small>
                    Fecha de creación del perfil {DayJS(data?.createdAt).format('L')}. Modificado{' '}
                    {DayJS(data?.updatedAt).fromNow()}, el {DayJS(data?.updatedAt).format('LLL')}
                </small>
            </footer>
        </Modal>
    )
}

export const UserProfileIcon = ({ _profileId: profileId }: { _profileId: string }) => {
    const { role: loginAs } = useAuthContext()
    const { setProfileManage } = useUIContext()
    if (!loginAs) return null
    return (
        <Icon
            type="profile"
            title="Perfil del usuario"
            onClick={() => setProfileManage({ profileId, loginAs })}
        />
    )
}

// eslint-disable-next-line no-undef
export type ProfileData = Awaited<ReturnType<typeof getProfileDetails>>

export type ProfileDataWithMutation = ProfileData & {
    mutate: UseMutateFunction<ProfileData, unknown, UpdateProfileDataParams, Partial<ProfileData>>
}

export type ProfileMutationContent = ProfileDataWithMutation & {
    content?: ProfileDayOfContentResponse
    mutateContent: UseMutateFunction<
        ProfileDayOfContentResponse,
        unknown,
        [
            data: {
                profileId: string
                newContentDay: number
            },
        ],
        ProfileDayOfContentResponse
    >
}

export type ProfileManager = {
    profileId: string
    loginAs: UsersRoles
} | null

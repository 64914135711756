import { Title } from '../../../Components/Title'
import { FrequencyDTO } from '../api'
import { ScheduleList } from './ScheduleList'
import { useState } from 'react'
import { AddEditSchedule, newScheduleData } from './AddEditSchedule'
import DayJS from '../../../Helpers/DayJS'
import { AddEditFrequency } from './AddEditFrequency'

export const Frequency = (props: FrequencyDTO) => {
    const {
        id,
        title,
        sunday,
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        courses,
        networkId,
    } = props

    const [isOpen, setIsOpen] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const day_of_course = [sunday, monday, tuesday, wednesday, thursday, friday, saturday]
        .map((day, index) => (day ? DayJS().day(index).format(`dddd`) : null))
        .filter((day) => !!day)
        .join(', ')

    return (
        <>
            <section>
                <Title
                    title={`Frecuencia: ${title}`}
                    subtitle={[`ID: ${id}`, `_Dias de cursado: ${day_of_course}.`]}
                >
                    <button className="outlined" onClick={() => setIsEditing(true)}>
                        Editar frecuencia
                    </button>
                    <button className="action" onClick={() => setIsOpen(true)}>
                        Agregar horario
                    </button>
                </Title>
            </section>

            <ScheduleList courses={courses} />

            <AddEditSchedule
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                schedule={{ ...newScheduleData, networkId, frequencyId: id }}
            />

            <AddEditFrequency
                isOpen={isEditing}
                onClose={() => setIsEditing(false)}
                frequency={props}
            />
        </>
    )
}

import { ApiActivation } from '../Networks/api'
import Axios from 'axios'
import { UsersRoles } from '../../Interfaces/users'
import { arraySplitter } from '../../Helpers/utilities'
import { capitalize } from '../../Helpers/formatters'
import { parseDateFromApiToView } from '../../Helpers/dateFormatters'

export const searchUsers = (skip: number = 0, search: string = ''): Promise<ParsedUser[]> =>
    Axios.get<GetUsersList>(`/user?limit=15&skip=${skip}&search=${search}`).then(({ data }) => {
        const { users, temporalUsers } = data.data
        // Join the temporal users with the users, parse date string as Date object and make the capitalized displayName
        const parsedTemporalUsers = temporalUsers.map((user) => ({
            ...user,
            isTemporal: true,
            displayName: `${capitalize(user.name || '')} ${capitalize(user.lastname || '')}`,
            creationDate: parseDateFromApiToView(user.createdAt),
        }))
        const parsedUsers = users.map((user) => ({
            ...user,
            isTemporal: false,
            displayName: `${capitalize(user.name || '')} ${capitalize(user.lastname || '')}`,
            creationDate: parseDateFromApiToView(user.createdAt),
        }))

        return [...parsedUsers, ...parsedTemporalUsers]
    })

export const fetchUser = (id: string) =>
    ApiActivation.get<GetUser>(`/admin/users/${id}`).then(({ data }) => ({
        displayName: `${capitalize(data.data.user.name || '')} ${capitalize(
            data.data.user.lastname || '',
        )}`,
        ...data.data.user,
    }))

export const updateUser = (id: string, data: Partial<UserFromApi>) =>
    ApiActivation.put<PutUser>(`/admin/users/${id}`, data)

export const updateEmail = ({ id, email }: { id: string; email: string }) =>
    ApiActivation.put(`/admin/users/edit-email/${id}`, { email }).then(
        () => 'El email ha sido actualizado correctamente.',
    )

export const updatePassword = ({ id, password }: { id: string; password: string }) =>
    Axios.post(`v2/user/${id}/editPassword`, {
        password: password,
        password_confirmation: password,
    }).then(() => 'La contraseña ha sido actualizada correctamente.')

export const getUserProfiles = (id: string): Promise<GetUserProfiles['data']['profiles']> =>
    Axios.get<GetUserProfiles>(`/user/${id}/profiles`).then(({ data }) => {
        let { profiles, inactiveProfiles } = data.data
        const activeProf = profiles.map((item) => ({
            ...item,
            userId: id,
            active: true,
        }))
        const inactiveProf = inactiveProfiles?.map((item) => ({
            ...item,
            userId: id,
            active: false,
        }))
        if (!inactiveProf) return activeProf
        return [...activeProf, ...inactiveProf]
    })

export const getUserProfilesV3 = (userId: string) =>
    Axios.get<GetUserProfilesV3>(`/v3/profile/user/${userId}`).then(({ data }) => data.data)

export const updateUserProfile = ({
    profileId,
    data,
}: {
    profileId: string
    data: Partial<GetUserProfiles['data']['profiles'][0]>
}) => Axios.patch<UpdateProfileResponse>(`/profile/${profileId}`, data).then((data) => data.data)

/** Get payments from an User */
export const getProofPayments = (id: string): Promise<GetUserPayments['data']['proofPayments']> =>
    Axios.get<GetUserPayments>(`/user/${id}/proofPayments`).then((data) =>
        data.data.data.proofPayments.map((item) => ({
            ...item,
            userId: id,
            createdAt: parseDateFromApiToView(item.createdAt),
            updatedAt: parseDateFromApiToView(item.updatedAt),
        })),
    )

export const getUserProfile = (profileID: string) =>
    Axios.get<GetUserProfile>(`/profile/${profileID}`).then(({ data }) => data.data)

export const putProofPayments = ({ id, data }: { id: string; data: FormData }) =>
    Axios.put<PutUserPayment>(`/user/${id}/attachProofPayment`, data).then((response) => response)

export const getProofPaymentFile = (filename: string) =>
    Axios.get(`/v2/file/proofpayments/${filename}`, {
        responseType: 'blob',
    })
        .then(({ data }: { data: Blob }) => {
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(new Blob([data]))
            link.setAttribute('download', filename)
            document.body.appendChild(link)
            link.click()
            link.remove()
        })
        .catch((error) => Promise.reject(error))

/** Get User widget token */
export const getWidgetToken = (_profileId: string) =>
    Axios.post<GetWidgetTokenResponse>(`/v2/widget/createtoken`, { _profileId }).then(
        ({ data }) => data.data,
    )

/** SignIn like user */
export const signInLikeUser = (id: string) =>
    Axios.get<ResponseCustomToken>(`/user/${id}/customToken`).then(({ data }) => {
        const { adminUrl, customToken } = data.data
        window.open(`${adminUrl}/login/superadmin?token=${customToken}`)
    })

export const getLastProfileByEmails = (emails: string[]) =>
    Axios.post<GetLastProfileByEmails>(`/v3/user/lastProfileByEmails?initialRow=2`, {
        emails,
    }).then(({ data }) => data.data)

export const getAllBulkActionsReports = () =>
    Axios.get<GetAllBulkActionsReportsResponse>('v3/reports/bulkActions').then(
        ({ data }) => data.data,
    )

export const getOneBulkActionsReports = (id: string) =>
    Axios.get<GetOneBulkActionsReportsResponse>(`v3/reports/bulkActions/${id}`).then(
        ({ data }) => data.data,
    )

export const postBulkActions = (body: NewBulkActionReport) =>
    Axios.post<Omit<BulkActionDTO, 'emails'>>('v3/reports/bulkActions', body).then(
        ({ data }) => data,
    )

export const newBulkActionsReport = (
    type: 'Alta' | 'Baja',
    curseName: string,
    emails: string[],
    MAX = 5000,
) => {
    const length = emails.length
    const name = `${type} - ${curseName}`
    if (length < MAX) postBulkActions({ emails, name })
    else {
        // Split emails arrays
        const parts = arraySplitter(emails, MAX)
        const totals = parts.length
        parts.forEach((emails, index) => {
            const partName = `${name} ${index + 1}/${totals}`
            postBulkActions({ emails, name: partName })
        })
    }
}

export const getProfileDetails = (_profileId: string) =>
    Axios.get<GetProfileDetails>(`/v3/profile/${_profileId}`).then(({ data }) => ({
        ...data.data,
        displayName: `${capitalize(`${data.data._userId.lastname}`)} ${capitalize(
            `${data.data?._userId.name}`,
        )}`,
    }))

export const getAllUserProfiles = (userID: string) =>
    Axios.get<GetAllUserProfile>(`v3/profile/user/${userID}`).then(({ data }) =>
        data.data.map((profile) => ({
            ...profile,
            displayName: `${capitalize(profile._userId.lastname)} ${capitalize(
                profile._userId.name,
            )}`,
        })),
    )

export const updateProfileData = (_profileId: string, data: Partial<UpdateProfileData>) =>
    Axios.put<GetProfileDetails>(`/v3/profile/${_profileId}`, data).then(({ data }) => ({
        ...data.data,
        displayName: `${capitalize(`${data.data._userId.lastname}`)} ${capitalize(
            `${data.data?._userId.name}`,
        )}`,
    }))

export const getProfileDayOfContent = (profileId: string) =>
    Axios.get<ProfileDayOfContentResponse>(`content/get-profile-content?profileId=${profileId}`, {
        baseURL: `${process.env.REACT_APP_STATS_URL}`,
    }).then(({ data }) => data)

export const updateProfileDayOfContent = (data: { profileId: string; newContentDay: number }) =>
    Axios.post<ProfileDayOfContentResponse>(`content/new-profile-content`, data, {
        baseURL: `${process.env.REACT_APP_STATS_URL}`,
    }).then(({ data }) => data)

export const getSelfEnrolledOmitted = (data: { from: string; to: string }) =>
    Axios.post<SelfEnrolledOmittedDTO[]>(`users/failed`, data, {
        baseURL: `${process.env.REACT_APP_STATS_URL}`,
    }).then(({ data }) => data)

export const reEnrollSelfOmitted = (omittedUsers: ReEnrollUserDTO[]) =>
    Axios.put<SelfEnrolledOmittedDTO[]>('users/reenrollment', omittedUsers, {
        baseURL: `${process.env.REACT_APP_STATS_URL}`,
    }).then(({ data }) => data)

export const trackProfile = ({ _id, tracking }: { _id: string; tracking: ProfileTrackingDTO }) =>
    Axios.put<ProfileTrackingDTO[]>(`/v3/profile/${_id}/track`, tracking).then(({ data }) => data)

export const verifyUser = (id: string) => ApiActivation.post(`/admin/users/verify/${id}`)

// Interfaces

type AlgorithmType = 'hca1' | 'hca4' | 'default' | 'repeated'
type AlgorithmTypeSecondary = 'maingrowing' | 'onetable'
export interface GetUsersList {
    data: {
        users: UserFromApi[]
        temporalUsers: UserFromApi[]
        totalCount: number
        totalCountTemporal: number
    }
    message: string
}

export interface UserFromApi {
    address?: {
        street: string
        numeration: string
        floor?: string
        apartment?: string
        country: string
        city: string
    }
    active: boolean
    language: string
    isVerified: boolean
    loginCount: number
    termsAndConditions: boolean
    _id: string
    uid: string
    email: string
    provider: string
    name: string
    lastname: string
    displayName?: string
    phone?: string
    createdAt: string
    lastLogin: string
    genre?: string
    tutorialWidgetCompleted: boolean
    dni?: string
    cuil?: string
    statusMessage?: string
    birthdate?: string
    termsCreatedAt: string
    urlImage?: string
    pushId?: string
    acquisitionType: string
    institution: string
    intercomId?: string
    referral?: {
        code: string
        link: string
        referrer: string | null
    }
    contactType?: 'B2C' | 'B2B2C'
}

export interface ParsedUser extends UserFromApi {
    isTemporal: boolean
    displayName: string
    creationDate: string
}

interface GetUser {
    data: {
        user: UserFromApi
        message: string
    }
}

export interface PatchUser {
    data: UserFromApi
    message: string
}

export interface PutUser {
    data: Pick<
        UserFromApi,
        '_id' | 'name' | 'lastname' | 'phone' | 'birthdate' | 'genre' | 'intercomId' | 'dni'
    >
    message: string
}

interface ResponseCustomToken {
    data: {
        user: {
            _id: string
            name: string
            lastname: string
            email: string
        }
        customToken: string
        adminUrl: string
    }
    message: string
}

export interface GetUserProfiles {
    data: {
        profiles: {
            userId: string
            active: boolean
            _id: string
            role: string
            progressiveData?: {
                start: string
            }
            deleteDate?: string
            quitReason?: string
            course?: {
                _id: string
                name: string
            }
            hq?: {
                _id: string
                name: string
            }
            zoom: {
                id: string
                email: string
            }[]
        }[]
        inactiveProfiles?: {
            userId: string
            _id: string
            role: string
            progressiveData?: {
                start: string
            }
            deleteDate: string
            quitReason?: string
            course: {
                _id: string
                name: string
            }
            hq: {
                _id: string
                name: string
            }
            zoom: {
                id: string
                email: string
            }[]
        }[]
    }
    message: string
}

interface UpdateProfileResponse {
    data: {
        profile: {
            totalVotes: number
            _id: string
            role: string
            _userId: string
            _hqId: string
            _classId: string
            active: boolean
            name: string
            lastname: string
            email: string
            zoom: {
                id: string
                email: string
            }[]
            lastVotes: any[]
            createdAt: string
        }
    }
    message: string
}

export interface GetUserProfile {
    data: {
        _id: string
        user: {
            _id: string
            uid: string
            active: boolean
            email: string
            name: string
            lastname: string
            statusMessage: string
            urlImage: string
        }
        hq: {
            _id: string
            name: string
        }
        course: {
            _id: string
            name: string
        }
    }
    message: string
}

export interface GetUserPayments {
    data: {
        proofPayments: {
            _id: string
            _userId: string
            filename: string
            createdAt: string
            updatedAt: string
        }[]
    }
    message: string
}

interface GetWidgetTokenResponse {
    data: {
        profile: {
            _id: string
            _userId: string
            role: string
            email: string
            _classId: string
            _hqId: string
        }
        token: string
    }
    message: string
}

export interface PutUserPayment {
    data: {
        proofPayment: {
            _id: string
            _userId: string
            filename: string
            createdAt: string
            updatedAt: string
        }
    }
    message: string
}

export interface LastProfileByEmailsDTO {
    row: number
    _id: string | null
    createdAt?: string
    displayName?: string
    email: string
    intercomId?: string
    profile_count: number
    moodleId?: string
    profile: {
        _id?: string
        moodleId?: string
        courseData: {
            courseName: string
            alias: string
        }
        active: boolean
        start?: string
        quitDate?: string
        createdAt?: string
        institution?: string
        acquisitionType?: string
    } | null
}

export interface GetLastProfileByEmails {
    data: LastProfileByEmailsDTO[]
    message: string
}

interface GetAllBulkActionsReportsResponse {
    data: {
        _id: string
        name: string
        createdAt: string
    }[]
    message: string
}

interface GetOneBulkActionsReportsResponse {
    data: {
        _id: string
        name: string
        createdAt: string
        updatedAt: string
        emails: string[]
    }
    message: string
}

export type BulkActionDTO = {
    _id: string
    name: string
    emails: string[]
    createdAt: Date
    updatedAt: Date
}

export interface GetUserProfilesV3 {
    data: {
        stats: {
            attendances: {
                totalPresent: number
                totalAbsent: number
                totalDelay: number
            }
            totalGivenVotes: number
            totalReceivedVotes: number
        }
        totalVotes: number
        _id: string
        role: string
        _userId: {
            _id: string
            name: string
            lastname: string
        }
        _hqId: {
            _id: string
            name: string
        }
        _classId: {
            _id: string
            name: string
        }
        progressiveSchedule?: {
            start: string
        }
        setToUnactiveDate?: string
        quitReason?: string
        moodleId?: string
        active: boolean
        votes: number
        timesFacilitator: number
        lastVotes: any[]
        name: string
        lastname: string
        email: string
        zoom: any[]
        createdAt: string
        institution: string
        acquisitionType: string
        updatedAt: string
    }[]
    message: string
}

export type NewBulkActionReport = Omit<BulkActionDTO, 'createdAt' | '_id' | 'updatedAt'>

interface GetProfileDetails {
    data: UserProfile
    message: string
}
interface GetAllUserProfile {
    data: UserProfile[]
    message: string
}

export interface UserProfile {
    _id: string
    _professorId?: string
    _professorScheduleId?: string
    acquisitionType?: string
    active: boolean
    createdAt?: Date
    email?: string
    givenVotes?: number
    institution?: string
    isExpertMentor: boolean
    lastname?: string
    moodleId?: string
    name?: string
    progressiveSchedule?: { start: Date; end?: Date }
    quitReason?: string
    role: UsersRoles
    setToUnactiveDate?: Date
    timesFacilitator?: number
    totalVotes?: number
    updatedAt: Date
    urlImage?: string
    votes?: number
    _classId?: {
        _id: string
        algorithmType: AlgorithmType
        algorithmTypeSecondary?: AlgorithmTypeSecondary
        active: boolean
        name: string
        courseDuration: {
            startDateCourse?: string
            endDateCourse?: string
        }
    }
    _hqId?: {
        _id: string
        name: string
    }
    _userId: {
        _id: string
        name: string
        lastname: string
    }
    lastVotes: {
        _algorithmId: string
        votes?: number
    }[]
    stats?: {
        attendances: {
            totalPresent: number
            totalAbsent: number
            totalDelay: number
        }
        totalGivenVotes?: number
        totalReceivedVotes?: number
    }
    zoom?: {
        id: string
        email: string
    }[]
    zoomMeet?: {
        zoomUrl: string
        registrantId: string
    }
    trackings: {
        message: string
        type:
            | 'enroll'
            | 'promoted'
            | 'modified'
            | 'new-start-date'
            | 'other'
            | 'pause'
            | 'unenroll-auto'
            | 'unenroll'
        date: string
    }[]
}

export type TrackingDTO = UserProfile['trackings'][0]

export interface UpdateProfileData extends UserProfile {
    start?: string
}

export type UpdateProfileDataParams = Parameters<typeof updateProfileData>

export interface ProfileDayOfContentResponse {
    description: string
    id: string
    message: string
    day: number
}

export interface SelfEnrolledOmittedDTO {
    email: string
    name: string
    lastName: string
    enrollmentDate: Date
    phoneNumber: string
    country: string
    courseName: string
    omitReason: string
    start: string
    sent: boolean
}

export type ReEnrollUserDTO = Pick<SelfEnrolledOmittedDTO, 'email' | 'start'>

export type ProfileTrackingDTO = UserProfile['trackings'][0]

export type UserProps = Awaited<ReturnType<typeof fetchUser>>

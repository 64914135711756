import { useState } from 'react'
import { quitReasons } from '../../../../AppConfig'
import { ProfileDataWithMutation, ProfileMutationContent } from '../ProfileManage'
import DayJS from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import es from 'dayjs/locale/es'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { Alert } from '../../../../Components/Alert'
DayJS.extend(relativeTime)
DayJS.extend(localizedFormat)
DayJS.locale(es)

export const ProfileActions = (data: ProfileMutationContent) => (
    <div style={{ margin: '0 auto auto 0', width: '100%' }}>
        {data.active ? <EditProfile {...data} /> : <ReactivateProfile {...data} />}
    </div>
)

const EditProfile = (props: ProfileDataWithMutation) => {
    const { algorithmType } = props._classId ?? {}
    const isHCA4 = algorithmType === 'hca4'

    if (isHCA4)
        return (
            <>
                <DisabledProfile {...props} isHCA4={isHCA4} />
                <ExpertMentor {...props} />
            </>
        )
    else return null
}

const ReactivateProfile = ({
    _id,
    _classId,
    quitReason,
    setToUnactiveDate,
    content,
    role,
    progressiveSchedule,
    mutate,
    mutateContent,
}: ProfileMutationContent) => {
    const [start, setStart] = useState(DayJS().add(1, 'day').hour(0).minute(0).toJSON())
    const { algorithmType, courseDuration } = _classId ?? {}
    const { startDateCourse, endDateCourse } = courseDuration ?? {}
    const isHCA4 = algorithmType === 'hca4'
    const onlyHcaText = !isHCA4 ? '*HCA4' : ''

    const isValidStartDay = DayJS(start).isAfter(DayJS())

    const onSave = () => {
        mutate([_id, { active: true, start }])
        mutateContent([{ profileId: _id, newContentDay: 1 }])
    }

    const { day } = content ?? {}

    const minStart = progressiveSchedule?.start
        ? DayJS(progressiveSchedule.start).format('YYYY-MM-DDTHH:mm')
        : startDateCourse
        ? DayJS(startDateCourse).format('YYYY-MM-DDTHH:mm')
        : undefined

    const maxStart = endDateCourse ? DayJS(endDateCourse).format('YYYY-MM-DDTHH:mm') : undefined

    return (
        <>
            <p className="danger">
                ATENCIÓN: Este perfil fue desactivado {DayJS(setToUnactiveDate).fromNow()}, el{' '}
                {DayJS(setToUnactiveDate).format('LLL')}, porque
                <i> {` "${quitReason}"`.toLowerCase()}</i>.
            </p>
            {isHCA4 && role === 'student' && (
                <>
                    <label>
                        Fecha de inicio de cursado: {onlyHcaText}
                        <input
                            style={{ width: '40ch' }}
                            name="start"
                            type="datetime-local"
                            min={minStart}
                            max={maxStart}
                            value={DayJS(start).format('YYYY-MM-DDTHH:mm')}
                            onChange={({ target: { value } }) =>
                                value ? setStart(new Date(value).toJSON()) : ''
                            }
                            disabled={!isHCA4}
                        />
                    </label>

                    {day !== 1 && (
                        <p className="danger">El contenido académico será restaurado al día 1.</p>
                    )}
                </>
            )}
            <br />
            <button className="success" disabled={isHCA4 && !isValidStartDay} onClick={onSave}>
                Reactivar
            </button>
        </>
    )
}

const DisabledProfile = ({
    _id,
    mutate,
    isHCA4,
}: ProfileDataWithMutation & { isHCA4: boolean }) => {
    const [quitReason, setQuitReason] = useState('')

    const onSave = () => {
        mutate([
            _id,
            {
                active: false,
                quitReason: quitReason,
            },
        ])
    }

    return (
        <>
            {isHCA4 && (
                <>
                    <label>
                        Motivo de Baja:
                        <input
                            style={{ width: '50ch' }}
                            name="quitReason"
                            list="quitReasonList"
                            type="text"
                            value={quitReason}
                            onChange={({ target: { value } }) => setQuitReason(value)}
                            disabled={!isHCA4}
                        />
                    </label>
                    <datalist id="quitReasonList">
                        {quitReasons.map((reason) => (
                            <option key={reason}>{reason}</option>
                        ))}
                    </datalist>
                </>
            )}
            <button className="danger" disabled={isHCA4 && !quitReason} onClick={onSave}>
                Pausar
            </button>
        </>
    )
}

const ExpertMentor = ({
    _id,
    displayName,
    isExpertMentor,
    mutate,
    role,
}: ProfileDataWithMutation) => {
    const [isOpen, setIsOpen] = useState(false)
    if (role !== 'student') return null

    if (isOpen)
        return (
            <Alert
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                title={
                    <div style={{ textAlign: 'center' }}>
                        <h2>Convertir en Mentor Experto</h2>
                        <p style={{ maxWidth: 300 }}>
                            ¿ Está seguro que desea convertir a <b>{displayName}</b> en Mentor
                            Experto y avanzar su contenido al último día?
                        </p>
                        <p className="danger">
                            <b>¡Esta acción no puede ser revertida!</b>
                        </p>
                    </div>
                }
                onConfirm={() => mutate([_id, { isExpertMentor: true }])}
            />
        )

    if (!isExpertMentor)
        return (
            <section>
                <h3>Mentor Experto</h3>
                <p>
                    <small>
                        Al convertir al estudiante en <b>Mentor Experto</b>, se avanzará el
                        contenido del mismo al último día.
                    </small>
                </p>
                <button className="action" onClick={() => setIsOpen(true)}>
                    Convertir en Mentor Experto
                </button>
            </section>
        )
    else
        return (
            <section>
                <h3>Mentor Experto</h3>
                <p className="info">El usuario es Mentor Experto.</p>
            </section>
        )
}

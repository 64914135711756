import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Title } from '../../../Components/Title'
import { useSchedule } from '../Hooks/useSchedule'
import { CohortsList } from './CohortsList'
import { AddEditSchedule } from './AddEditSchedule'
import { useNetwork } from '../Hooks/useNetwork'
import { capitalize } from '../../../Helpers/formatters'

export const Schedule = () => {
    const { id = '' } = useParams()
    const { schedule, isLoading, isError } = useSchedule({ scheduleId: id })
    const [isEditing, setIsEditing] = useState(false)
    const { network } = useNetwork(schedule?.networkId)

    if (isLoading) return <p>Cargando información del horario...</p>
    if (isError) return <p className="danger">Error al cargar la información del horario...</p>
    if (!schedule) return <p className="danger">El horario no existe.</p>

    return (
        <>
            <Title
                title={`Horario: ${schedule.alias}`}
                subtitle={[
                    `_Red: ${capitalize(network?.title)}`,
                    `Horario ID: ${id}`,
                    `Mongo ID: ${schedule.mongoId}`,
                ]}
            >
                <button className="outlined" onClick={() => setIsEditing(true)}>
                    Editar Horario
                </button>
            </Title>

            <AddEditSchedule
                isOpen={isEditing}
                onClose={() => setIsEditing(false)}
                schedule={schedule}
            />

            <CohortsList {...schedule} scheduleId={id} />
        </>
    )
}

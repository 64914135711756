import { UseQueryResult, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import DayJS from '../../../Helpers/DayJS'
import { Icon } from '../../../Components/Icon'
import { ProfilesStats } from '../../../Components/ProfilesStats'
import { ProfilesStatsDTO } from '../../Cohort/api'
import { Title } from '../../../Components/Title'
import { capitalize } from '../../../Helpers/formatters'
import { networkStats } from '../api'
import { useNetwork } from '../Hooks/useNetwork'
import { useState } from 'react'
import { useUIContext } from '../../../Context/UIContext'

const ITD = 'YYYY-MM-DD'
const now = DayJS()

export const NetworkStats = () => {
    const { id = '' } = useParams()
    const { network } = useNetwork(id)
    const { showToast, showError } = useUIContext()
    const navigate = useNavigate()

    const from = now.format(ITD)
    const to = now.add(1, 'year').format(ITD)

    const [dates, setDates] = useState({ from, to })

    const result = useQuery({
        queryFn: () => networkStats({ id, ...dates }),
        queryKey: ['Network Stats', id],
        onError: showError,
        enabled: false,
    })

    const { isFetching, refetch } = result

    return (
        <>
            <Title
                title={`Redes - ${capitalize(network?.title)}`}
                subtitle={[`ID: ${id}`, `Tipo de contenido: ${network?.contentType}`]}
            >
                {isFetching && <p>Cargando información de las estadísticas...</p>}
                <div className="flex-search" style={{ margin: '-5px 0' }}>
                    <div className="flex-elements">
                        <label>
                            Desde
                            <input
                                id="fromDate"
                                name="from"
                                type="date"
                                value={dates.from}
                                max={dates.to}
                                disabled={isFetching}
                                onChange={({
                                    target: {
                                        name,
                                        value,
                                        validity: { valid },
                                        validationMessage,
                                    },
                                }) => {
                                    setDates((prev) => ({ ...(prev ?? {}), [name]: value }))
                                    !valid && showToast(validationMessage, 'error')
                                }}
                            />
                        </label>
                        <label>
                            Hasta
                            <input
                                id="toDate"
                                name="to"
                                type="date"
                                value={dates.to}
                                disabled={isFetching}
                                onChange={({
                                    target: {
                                        name,
                                        value,
                                        validity: { valid },
                                        validationMessage,
                                    },
                                }) => {
                                    setDates((prev) => ({ ...(prev ?? {}), [name]: value }))
                                    !valid && showToast(validationMessage, 'error')
                                }}
                            />
                        </label>
                    </div>
                    <button
                        className="action"
                        name="submit"
                        style={{ margin: 'auto 22px 8px 22px' }}
                        disabled={isFetching}
                        onClick={() => refetch()}
                    >
                        Buscar
                    </button>

                    <Icon
                        type="user"
                        className="outlined"
                        style={{ margin: '15px 0', alignSelf: 'end' }}
                        onClick={() => navigate(`../${id}`)}
                    />
                </div>
            </Title>

            <Stats {...result} />
        </>
    )
}

const Stats = ({ data, isFetching, isError }: StatsProps) => {
    if (isFetching)
        return <p>Cargando información de las estadísticas, aguarde un momento por favor.</p>

    if (isError) return <p className="danger">Hubo un error</p>

    if (!data) return null

    if (!data.items) return <p>No hay datos para este rango de fecha.</p>

    return <ProfilesStats stats={data} />
}

type StatsProps = UseQueryResult<ProfilesStatsDTO, unknown>
